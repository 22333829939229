@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.main_wrapper {
    width: 100%;
    height: 100%;
}
.main_section {
    width: 100%;
}


/*top*/

.top {
    width: 100%;
    margin-bottom: 30px;
}
.top_wrapper {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
}

.top_slider_item {
    width: 100%;
    position: relative;
    background-image: url("../img/slider_back.png");
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 10px;
    background-color: #528DFF;
    padding: 41px 15px;
}

.top_slider_item_title {
    color: #FFF;
    font-family: 'Raleway', sans-serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
}
.top_slider_item_text {
    color: #FFF;
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.top_slider_item_wrapper {
    max-width: 432px;
    width: 100%;
    padding-left: 60px;
}


.prev_next_btns_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 999;
    bottom: 57%;
}

.prev {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    left: 15px;
}
.next {
    background: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: absolute;
    right: 15px;
}

.swiper-pagination {
    bottom: 18px;
    z-index: 9;
}

.swiper {
    padding-bottom: 40px!important;
}


/*real_vps_details*/

.real_vps_details {
    background:  #528DFF;
    padding: 25px 0;
    width: 100%;
    margin-bottom: 30px;
}
.real_vps_details_wrapper {
    max-width: 898px;
    width: 100%;
    margin: 0 auto;
}

.real_vps_details_item {
    width: 32%;
}
.real_vps_details_items_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.real_vps_details_item_title {
    color: #FFF;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 101.9%;
    font-variant-numeric: lining-nums proportional-nums;
}

.real_vps_details_item_info {
    color: #FFF;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 101.9%;
}

/*real_vps_details_filter*/
.real_vps_details_filter {
    width: 100%;
    margin-bottom: 30px;

}
.real_vps_details_filters_wrapper {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
    border-radius: 10px;
    background:  #ECF2F6;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

}

.real_vps_details_filter_inputs_main_wrapper {
    width: 100%;
    /*margin-bottom: 20px;*/
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.real_vps_details_filter_min_max_inputs_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.real_vps_details_filter_input_field {
    border-radius: 8px;
    background: #FFF;
    /*width: 98px;*/
    width: 48%;
    height: 34px;
    border: none;
    text-align: center;
    outline: none;
    color: #333;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.real_vps_details_filter_input_field::placeholder {
    color: #333;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.real_vps_details_filter_min_max_inputs_wrapper div {
    margin: 0px 4px;
}


.real_vps_details_filter_input_title {
    color: #333;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 7px;
}

.real_vps_details_filters_dropdowns_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.real_vps_details_filters_dropdowns_title_wrapper {
    width: 19%;
    margin-bottom: 20px;
}

.real_vps_details_filters_dropdowns_title {
    color: #333;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 7px;
}

.real_vps_details_filters_dropdown {
    width: 100%;
    position: relative;
    cursor: pointer;
    height: 34px;
    border-radius: 8px;
    background: #FFF;

}

.real_vps_details_filters_dropdown_selected_option_icon_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 10px;
}

.real_vps_details_filters_dropdown_selected_option {
    color: #333;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.real_vps_details_filters_dropdown_options_list_wrapper {
    width: 100%;
    position: absolute;
     padding-bottom: 10px;
    cursor: pointer;
    /* height: 34px; */
    border-radius: 8px;
    background: #FFF;
    top: 0px;
    z-index: 8;
    box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.10);
    height: 200px;
    overflow: scroll;

}
.real_vps_details_filters_dropdown_options_list_wrapper::-webkit-scrollbar {
    display: none;
}
.real_vps_details_filters_dropdown_options_list_option {
    color: #333;
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 10px;
}

/*real_vps_details2*/
.real_vps_details2 {
    width: 100%;
    margin-bottom: 100px;
}
.real_vps_details2_wrapper {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
}

.real_vps_details2_info {
    color: #333;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 15px;
}

/*datatable_pagination*/
.datatable_pagination {
    width: 100%;
    margin-bottom: 80px;
}
.datatable_pagination_wrapper {
    max-width: 1170px;
    width: 100%;
    margin: 0 auto;
}
.table_img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.p-datatable-table {
    border-radius: 10px!important;
}


.p-datatable-thead tr {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.datatable_logo_title {
    color: #333;
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    margin-top: 5px;
}
.datatable_logo {
    width: 81px;
}

/*.p-datatable-tbody tr {*/
/*    background: #F8FAFC;*/
/*    width: 100%;*/
/*    !*padding: 20px 25px;*!*/
/*}*/

.p-datatable-tbody td {
    padding: 20px 25px;
    background: #F8FAFC;
    color: #333;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}

.p-column-header-content {
    border: none!important;
    background: #ECF2F6!important;
    padding: 10px 25px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.p-paginator-bottom.p-paginator.p-component {
    padding-top: 25px;
}

.p-paginator-page.p-paginator-element.p-link.p-paginator-page-start.p-paginator-page-end.p-highlight {
    border-radius: 3.85px;
    background: #528DFF;
    color: #FFF;
    font-family: 'Rubik',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.p-paginator-first.p-paginator-element.p-link.p-disabled svg path {
    fill: #528DFF80;
}

.p-dropdown-trigger svg path {
    fill: #528DFF;
}

.p-paginator-last.p-paginator-element.p-link.p-disabled svg path {
    fill: #528DFF;
}

.p-paginator-prev.p-paginator-element.p-link.p-disabled svg path {
    fill: #528DFF;
}

.p-paginator-next.p-paginator-element.p-link.p-disabled svg path {
    fill: #528DFF;

}

.p-paginator-page.p-paginator-element.p-link.p-paginator-page-start.p-highlight {
    border-radius: 3.85px;
    background: #528DFF;
    color: #FFF;
    font-family: 'Rubik',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.p-paginator-page.p-paginator-element.p-link.p-paginator-page-end.p-highlight {
    border-radius: 3.85px;
    background: #528DFF;
    color: #FFF;
    font-family: 'Rubik',sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.p-dropdown-label.p-inputtext.p-placeholder {
    display: none!important;
}

.p-dropdown-label.p-inputtext {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
    padding-left: 10px;
    padding-bottom: 6px;
}


/*media*/
@media (max-width: 1200px) {
    .top_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .real_vps_details_filters_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
    .real_vps_details2_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}


@media (max-width: 520px) {
    .top_slider_item_wrapper {
        padding-left: unset!important;
    }
    .prev_next_btns_wrapper {
        width: 83px!important;
        bottom: unset!important;
        top: 20px;
    }
}



.real_vps_details_filter_input_title_wrapper {
    width: 19%;
    margin-bottom: 20px;
}

@media (min-width: 571px) and (max-width: 900px) {
    .real_vps_details_filter_inputs_main_wrapper {
        flex-wrap: wrap;
        justify-content: flex-start!important;
    }
    .real_vps_details_filter_input_title_wrapper {
        width: 32% !important;
        margin-bottom: 20px;
        margin-right: 6px;
    }
    .real_vps_details_filters_dropdowns_title_wrapper {
        width: 32% !important;
    }
}

@media (min-width: 425px) and (max-width: 571px) {
    .real_vps_details_filter_inputs_main_wrapper {
        flex-wrap: wrap;
    }
    .real_vps_details_filter_input_title_wrapper {
        width: 48% !important;
        margin-bottom: 20px;
    }
    .real_vps_details_filters_dropdowns_title_wrapper {
        width: 48% !important;
    }
}

@media  (max-width: 425px) {
    .real_vps_details_filter_inputs_main_wrapper {
        flex-wrap: wrap;
    }
    .real_vps_details_filter_input_title_wrapper {
        width: 100% !important;
        margin-bottom: 20px;
    }
    .real_vps_details_filters_dropdowns_title_wrapper {
        width: 100% !important;
    }
    .real_vps_details2_info {
        font-size: 16px!important;
    }
    .real_vps_details2 {
        margin-bottom: 50px!important;
    }
    .datatable_pagination {
        margin-bottom: 50px!important;
    }
}

@media (max-width: 950px) {
    .real_vps_details_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}

@media (max-width: 550px) {
    .real_vps_details_items_wrapper {
        flex-direction: column;
        justify-content: center!important;
        align-items: center!important;
    }
    .real_vps_details_item {
        width: 100% !important;
        margin-bottom: 25px;
    }
}

.tariff_wrapper {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
    margin-bottom: 80px;
    border-radius: 10px;
}
.tariff_titles_wrapper {
    background:  #ECF2F6;
    padding: 10px 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 0.5px solid  rgba(51, 51, 51, 0.30);
}

.tariff_title {
    color: #333;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    width: 11%
}

.tariff_title:nth-child(7) {
    width: 13% !important
}

.tariff_item_img {
    /*width: 81px;*/
    width: 11%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.tariff_item_img img {
    width: 81px;
    height: 81px;
    object-fit: cover;
    margin-bottom: 5px;
}
.tariff_item_img_info {
    color: #333;
    font-family: 'Raleway', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-align: center;
}
.tariff_item_info {
    color: #333;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: 'Raleway', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    width: 11%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tariff_item {
    background: #F8FAFC;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}
.tariff_item:not(:last-child) {
    border-bottom: 0.5px solid  rgba(51, 51, 51, 0.30);

}
.tariff_item_img_child {
    display: flex;
    flex-direction: column;
    width: 81px;
    justify-content: center;
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    outline: none;
}

@media (max-width: 950px) {
    .tariff_titles_wrapper {
        overflow: auto;
        white-space: nowrap;
        padding: 7px 5px!important;
    }
    .tariff_title {
        width: auto!important;
        padding: 4px 20px;
    }
    .tariff_title:nth-child(7) {
        width: auto!important;
    }

    .tariff_item {
        overflow: auto;
        white-space: nowrap;
        padding: 15px 0px!important;
    }
    .tariff_item_info {
        width: auto!important;
        padding: 4px 20px;
    }
    .tariff_item_img {
        width: auto!important;
        padding: 4px 20px;
    }
}

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


/*faq*/
.faq {
    width: 100%;
    margin-bottom: 100px;
}
.faq_wrapper {
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}
.faq_title {
    color: #333;
    font-variant-numeric: lining-nums proportional-nums;
    font-family: 'Raleway', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 50px;
}
.faq_items_wrapper {
   width: 100%;
}
.faq_item {
    border-radius: 20px;
    border: 3px solid #ECF2F6;
    background: #FFF;
    width: 100%;
    padding: 20px;
    margin-bottom: 20px;

}

.faq_item_title_icon_wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.faq_item_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: none;
    border: none;
}

.faq_item_title {
    color: #070707;
    leading-trim: both;
    text-edge: cap;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.faq_item_hidden_info {
    color: #070707;
    leading-trim: both;
    text-edge: cap;
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.faq_item_hidden_info_box {
    padding-top: 10px;
}


/*media*/

@media (max-width: 1200px) {
    .faq_wrapper {
        max-width: unset!important;
        width: 95% !important;
    }
}

@media (min-width: 475px) and (max-width: 768px) {
    .faq_item_title {
        font-size: 18px!important;
    }
    .faq_item_hidden_info {
        font-size: 16px!important;
    }
}

@media (max-width: 475px) {
    .faq_title {
        margin-bottom: 25px!important;
    }
    .faq_item_title {
        font-size: 16px!important;
    }
    .faq_item_hidden_info {
        font-size: 14px!important;
    }
    .faq_item {
        border-radius: 10px!important;
        padding: 12px!important;
    }
    .faq_item_btn img {
        width: 20px!important;
        height: 20px!important;
    }
    .faq {
        margin-bottom: 50px!important;
    }
}


